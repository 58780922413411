import { Component } from "react";
import DropDown from "./DropDown";

class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    render() {
        let rawData = [];
        let headers = this.props.headers;
        if(this.props.data !== undefined && this.props.data.length > 0) {
            rawData = this.props.data;
        } else {
           
        }
        return(
            <div>
                <table className="gridTableCls">
                    <tr>
                        <td className="gridColumnCls gridHeaderCls" colSpan={4}>{this.props.title}                            
                        </td>
                    </tr>
                    <tr>
                        <td style={{width: "15%"}}></td>
                        <td style={{width: "50%"}}></td>
                        <td style={{width: "25%"}}>
                            <DropDown options={this.props.statusOptions} 
                                    onSelect={this.props.onSelectStatus} 
                                    value = {this.props.defaultStatusValue}
                                />
                        </td>
                        <td style={{width: "10%"}}>
                            <DropDown options={this.props.responseOptions} 
                                    onSelect={this.props.onSelectResponse} 
                                    value = {this.props.defaultResponseValue}
                                />
                        </td>
                    </tr>
                    <tr>
                        {Object.entries(headers).map(([key, object]) =>                        
                            <td style={{"width": ""+object.width, "font-weight": "500"}} className="gridColumnCls">{object.label}</td>
                        )}
                    </tr>
                    {Object.entries(rawData).map(([key, object]) =>
                        <tr>
                            {Object.entries(headers).map(([key, obj]) =>                        
                                <td style={{"width": ""+obj.width}} className="gridColumnCls">{object[obj.id] !== undefined ? object[obj.id] : ""}</td>
                            )}
                        </tr>
                    )}
                </table>
            </div>
        )
    }
}
export default Grid;