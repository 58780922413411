import { Component } from "react";
import TextArea from "./TextArea";
import Button from "./Button";
import Grid from "./Grid";
import * as Utils from '../Utils';

class MultiUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlsContent: "",
            gridHeader: [
                {id: "id", label: "S.No", width: "15%"},
                {id: "url", label: "URL", width: "50%"},
                {id: "status", label: "Status", width: "25%"},
                {id: "response", label: "Response", width: "10%"}
            ],
            gridData: [],
            orginalGridData: [],
            statusOptions:[
                {value: 'ALL', label: 'ALL'},
                {value: 'Success', label: 'Success'},
                {value: 'Failed', label: 'Failed'}
            ],
            responseOptions:[
                {value: 'ALL', label: 'ALL'},
                {value: '200', label: '200'},
                {value: '403', label: '403'}
            ]
        }
        this.validateClick = this.validateClick.bind(this);
        this.changeContent = this.changeContent.bind(this);
        this.openAllUrls = this.openAllUrls.bind(this);
        this.processUrls = this.processUrls.bind(this);
        this.closeAllUrls = this.closeAllUrls.bind(this);
        this.onSelectStatus = this.onSelectStatus.bind(this);
        this.onSelectResponse = this.onSelectResponse.bind(this);
        this.processFiltering = this.processFiltering.bind(this);
    }
    validateClick(event) {
        this.processUrls("v");        
    }
    openAllUrls() {
        this.processUrls("oa");
    }
    closeAllUrls() {
        //this.processUrls("ca");
        Utils.closeAllTabs();
    }
    processUrls(type) {
        let data = [];
        let url = this.state.urlsContent;
        if(url !== undefined) {
            let urls = url.split('\n');
            Object.entries(urls).map(([key, value]) => {
                if(value !== undefined && value !== '') {
                    if(type === "oa") {
                        Utils.openUrlNewWindow(value, "_blank");
                    } else if(type === "ca") {

                    } else {
                        let obj;     
                        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
                        if(regex.test(value)) { 
                            obj = {id: data.length + 1, url: value, status: "Success", response: "200"}
                        } else {
                            obj = {id: data.length + 1, url: value, status: "Failed", response: "403"}
                        }
                        data.push(obj);
                    }
                }
                return "";
            });
        }
        this.setState({gridData: data, orginalGridData: data});
    }
    changeContent(event) {
        this.setState({urlsContent: event.target.value});
    }
    onSelectStatus(event) {
        let val = event.value;
        this.processFiltering(val, true, false);
    }
    onSelectResponse(event) {
        let val = event.value;
        this.processFiltering(val, false, true);
    }
    processFiltering(val, statusFlag, resFlag) {
        let filteredData = []
        if(val !== undefined && val !== '' && val !== 'ALL') {
            let gridData = this.state.orginalGridData;
            val = val.toLowerCase();
            Object.entries(gridData).map(([key, obj]) => {
                let statusVal =  obj.status !== undefined ? obj.status.toLowerCase() : "";
                let responseVal =  obj.response !== undefined ? obj.response.toLowerCase() : "";
                if((statusFlag && statusVal.indexOf(val) > -1) 
                    || (resFlag && responseVal.indexOf(val) > -1)) {
                    filteredData.push(obj);
                }
                return "";
            });
            this.setState({gridData: filteredData});
        } else {
            this.setState({gridData: this.state.orginalGridData});
        }
    }
    render() {
        return(
            <div>
                <h2>Past the multiple URLs </h2>
                <TextArea id="multi-url-id" rows="15" onChange={this.changeContent}/>
                <div>
                    <table>
                        <tr>
                            <td><Button id="open-all-url-id" label="Open All URLs" onClick={this.openAllUrls} /></td>
                            <td><Button id="close-opened-all-url-id" label="Close All Opened URLs" onClick={this.closeAllUrls} /></td>
                            <td><Button id="validate-all-url-id" label="Validate URLs" onClick={this.validateClick} /></td>
                        </tr>
                    </table>
                </div>
                <div>
                    <b>Note:</b> On click of Open All URLs, if opened only one window, please check the <i>"pop-ups were blocked"</i> from current window and allow pop-ups and try again
                </div>
                <br />
                <div>
                    <Grid headers={this.state.gridHeader} title="List of URLs" 
                        data={this.state.gridData} 
                        onSelectStatus = {this.onSelectStatus}
                        statusOptions = {this.state.statusOptions}
                        defaultStatusValue = {this.state.statusOptions[0]}
                        onSelectResponse = {this.onSelectResponse}
                        responseOptions = {this.state.responseOptions}
                        defaultResponseValue = {this.state.responseOptions[0]}
                    />
                </div>
            </div>
        )
    }
}
export default MultiUrl;