import { Component } from "react";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div>                
                About the project
            </div> 
          )
    }

}
export default About;