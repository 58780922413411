import { Component } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class DropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return(
            <div>
                <Dropdown className='drop-down-cls' options={this.props.options} onChange={event=>this.props.onSelect(event)} value={this.props.value} placeholder="--Select--" />
            </div>
        )
    }
}
export default DropDown;