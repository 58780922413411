import { Component } from "react";

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        let label = this.props.label !== undefined ? this.props.label : "Button";
        let id = this.props.id !== undefined ? this.props.id : "button-id";
        return (
            <div>
                <input className="activeBtnCls" id={id} type="button" value={label} 
                onClick={event=>this.props.onClick(event)} />
            </div>
        )
    }

}
export default Button;