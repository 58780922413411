import { Component } from "react";
import {Outlet, Link } from "react-router-dom";
import * as Utils from './Utils';
import Logo from '../logo.jpeg';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <div className="headerDivCls">
                    <div className="headerColCls" id="logoId"><img alt="Logo" style={{height: "65px"}} src={Logo} /></div>
                    <div className="headerColCls projectdesCls" id="descriptionId">Analyze Multiple URLs Tool </div>
                    <div className="headerColCls tabBtnCls" id="homelinkId"><Link to={"/"}>Home</Link> </div>
                    <div className="headerColCls tabBtnCls" id="aboutlinkId"><Link to={"/"+Utils.ABOUT_PATH}>About</Link> </div>
                    <div className="headerColCls tabBtnCls" id="contactlinkId"><Link to={"/"+Utils.CONTACT_PATH}>Contact</Link> </div> 
                </div>
                <div>
                    <div className="adsLeftDiv"></div>
                    <div className="centerDiv"><Outlet /></div>
                    <div className="adsRightDiv"></div>
                </div>
            </div> 
          )
    }

}
export default Home;