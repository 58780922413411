export const ABOUT_PATH = "about";
export const CONTACT_PATH = "contact";
var openedUrls = []
export function openUrlNewWindow(url) {
    openedUrls.push(window.open(url, "_blank"));
}
export function closeAllTabs() {
    var d = openedUrls.length;
    for(var i =0; i< d; i++) {
        openedUrls[i].close();
    }
    openedUrls = [];
}
export async function validateURL(url) {
    let result;
    await fetch(url)
    .then(response => {result = response.status})
    .then(data => console.log(data))
    .catch(error => {result = error});
    return result;
}