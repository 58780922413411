import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './main/Home';
import MultiUrl from './main/components/MultiUrl';
import About from './main/About';
import Contact from './main/Contact';
import * as Utils from './main/Utils';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = "Multiple URLs - Open Multiple URLs, Validate Multiple URLs, Close Opened Multiple URLs, Bulk links open, Bulk links validate"
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<MultiUrl />} />
          <Route path={Utils.ABOUT_PATH} element={<About />} />
          <Route path={Utils.CONTACT_PATH} element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
