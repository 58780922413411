import { Component } from "react";

class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        let rows = this.props.rows !== undefined ? this.props.rows : "4";
        let cols = this.props.cols !== undefined ? this.props.cols : "50";
        let id = this.props.id !== undefined ? this.props.id : "textarea-id";
        return (
            <div>
                <textarea id={id} type="textarea" rows={rows} cols={cols} style={{width: "100%"}} 
                onChange={event=>this.props.onChange(event)} />
            </div>
        )
    }

}
export default TextArea;